@layer components {
  .toast {
    background: var(--color-teal-600);
    border-radius: var(--border-radius-2x);
    position: fixed;
    top: var(--space-4x);
    left: 50%;
    padding: var(--space-2x);
    z-index: var(--z-index-6x);
    width: 96%;
    @media (--screen-md) {
      width: auto;
    }
  }

  .toastMessageBorder {
    width: 8px;
    height: 32px;
    background: var(--color-white);
    border-radius: var(--border-radius-2x);
    margin-right: var(--space-2-5x);
  }

  .toastMessage {
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-normal);
  }

  .toastEnter {
    transform-origin: center;
    transition: all 150ms ease-out;
  }

  .toastEnterFrom {
    transform: scale(0.5) translate3d(0, 10%, 0);
    opacity: 0;
  }

  .toastEnterTo {
    transform: scale(1) translate3d(-50%, 0, 0);
    opacity: var(--background-opacity);
  }

  .toastLeave {
    transition: all 250ms ease-out;
    transform-origin: center;
  }

  .toastLeaveFrom {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: var(--background-opacity);
  }

  .toastLeaveTo {
    transform: scale(0.5) translate3d(0, 10%, 0);
    opacity: 0;
  }

  .toastButton {
    align-items: center;
    color: var(--color-white);
    display: flex;
    justify-content: space-between;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  .toastIcon {
    font-size: var(--font-size-xl);
    margin-left: var(--space-4x);
    min-width: var(--space-5x);
  }
}
